import { Button, Tooltip } from "antd"
import React from 'react';
import { PrinterOutlined  } from "@ant-design/icons"
import useStyles from "hooks/useStyles";

const PrinterButton = ({ onClick, ...rest }) => {
  const { colorTextDisabled } = useStyles();

  return (
    <Tooltip title={!rest.disabled && "Imprimir"} mouseLeaveDelay={0}>
      <Button
        type="text"
        onClick={onClick}
        icon={<PrinterOutlined  style={{ color: rest.disabled && colorTextDisabled }} />}
        {...rest}
      />
    </Tooltip>
  );
};

export default PrinterButton;
