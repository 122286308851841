import { Card, Space, Row, Col, Typography } from "antd";
import useWrapperStyles from "hooks/useWrapperStyles";
import './styles.scss'
const { Text, Title } = Typography;

const ContentWithHeader = ({ children, title, subtitle, breadcrumb, rightContent, topRightButton }) => {
  const { wrapperStyles } = useWrapperStyles();

  return (
    <>
      <Card>
        {breadcrumb}
        {(title || rightContent) &&
          <Row justify="space-between" align="middle">
            <Col>
              <Space>
                <Title level={4}>{title}</Title>
                {subtitle &&
                  <Text type="secondary">{subtitle}</Text>
                }
              </Space>
            </Col>
            <Col>
              <Space>
                {topRightButton}
                {rightContent}
              </Space>
            </Col>
          </Row>
        }
      </Card>
      {children &&
        <Card bordered size="small" style={wrapperStyles}>
          {children}
        </Card>
      }
    </>
  )
};

export default ContentWithHeader;