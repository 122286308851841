
import { config } from "config"
import { options } from "utils";

export const getAll = async (q) => {
  const url = `${config.URL_API_PRIVATE}/tramos?` + q
  const response = await fetch(url, options("GET"));
  const data = await response.json();
  return data;
};

export const updateTramo = async (body) => {
  const url = `${config.URL_API_PRIVATE}/tramos`
  const response = await fetch(url, options("PUT", body));
  const data = await response.json();
  return data;
};

export const getAllTramos = async (q = '') => {
  const url = `${config.URL_API_PRIVATE}/tramos/all?` + q
  const response = await fetch(url, options("GET"));
  const data = await response.json();
  return data;
};