import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  usuario: undefined,
  actions_on_view: undefined,
  loading: undefined,

  activeTabKey: "",
  isTabChanged: false,
  tema: parseInt(localStorage.getItem('tema')) || 0,
}

export const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setActiveTabKey: (state, { payload }) => {
      state.activeTabKey = payload
    },
    setIsTabChanged: (state, { payload }) => {
      state.isTabChanged = payload
    },
    setUsuario: (state, { payload }) => {
      state.usuario = payload
    },
    setActionsOnView: (state, { payload }) => {
      state.actions_on_view = payload
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    setTema: (state, { payload }) => {
      state.tema = payload;
      localStorage.setItem('tema', payload);
    },
  },
})

export const generalActions = generalSlice.actions

export default generalSlice.reducer