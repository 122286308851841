import { configureStore } from '@reduxjs/toolkit'
import clientesEditarSlice from 'routes/clientes/Editar/clientesEditarSlice'
import contratistasSlice from 'routes/contratistas/contratistasSlice'
import filialesCoberturaSlice from 'routes/filialesEditar/components/CoberturaTab/filialesCoberturaSlice'
import filialesContactosSlice from 'routes/filialesEditar/components/ContactosTab/filialesContactosSlice'
import filialesDistanciasSlice from 'routes/filialesEditar/components/DistanciasTab/filialesDistanciasSlice'
import filialesDomiciliosSlice from 'routes/filialesEditar/components/DomiciliosTab/filialesDomiciliosSlice'
import filialesExclusionesSlice from 'routes/filialesEditar/components/ExclusionesTab/filialesExclusionesSlice'
import filialesLocalidadesSlice from 'routes/filialesEditar/components/LocalidadesTab/filialesLocalidadesSlice'
import filialesSegurosSlice from 'routes/filialesEditar/components/SegurosTab/filialesSegurosSlice'
import filialesZonasSlice from 'routes/filialesEditar/components/ZonasTab/filialesZonasSlice'
import filialesEditarSlice from 'routes/filialesEditar/filialesEditarSlice'
import usuariosEditarSlice from 'routes/usuarios/components/Editar/usuariosEditarSlice'
import generalSlice from './generalSlice'
import filialesTitularesSlice from 'routes/filialesEditar/components/TitularesTab/filialesTitularesSlice'
import filialesFirmantesSlice from 'routes/filialesEditar/components/FirmantesTab/filialesFirmantesSlice'
import filialesComisionesSlice from 'routes/filialesEditar/components/ContratoTab/ComisionesTab/filialesComisionesSlice'
import filialesVehiculosSlice from 'routes/filialesEditar/components/VehiculosTab/filialesVehiculosSlice'

export const store = configureStore({
  reducer: {
    general: generalSlice,

    usuariosEditar: usuariosEditarSlice,

    clientesEditar: clientesEditarSlice,

    filialesEditar: filialesEditarSlice,
    filialesCobertura: filialesCoberturaSlice,
    filialesZonas: filialesZonasSlice,
    filialesDomicilios: filialesDomiciliosSlice,
    filialesLocalidades: filialesLocalidadesSlice,
    filialesContactos: filialesContactosSlice,
    filialesComisiones: filialesComisionesSlice,
    filialesExclusiones: filialesExclusionesSlice,
    filialesSeguros: filialesSegurosSlice,
    filialesDistancias: filialesDistanciasSlice,
    filialesTitulares: filialesTitularesSlice,
    filialesFirmantes: filialesFirmantesSlice,
    filialesVehiculos: filialesVehiculosSlice,

    contratistas: contratistasSlice,
  },
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["filialesCoberturas/setZonaCrear", "filialesCoberturas/setZonas", "filialesZonas/setZonaCrear", "filialesZonas/setZonas",],
        ignoredPaths: ["filialesCobertura.zonaCrear", "filialesCobertura.zonas", "filialesZonas.zonaCrear", "filialesZonas.zonas",]
      }
    })
  }
})