import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';

const Loading = ({ height, fullscreen }) => {
  const indicator = <LoadingOutlined spin style={{ fontSize: 36 }} />
  return (
    <div
      style={{
        height: height ? height : fullscreen ? "100vh" : "100%",
        width: fullscreen ? "100%" : "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Spin
        indicator={indicator}
      />
    </div>
  )
}

export default Loading