import useQuery from "hooks/useQuery";
import { showErrorModal } from "utils";
import { getLinea } from "./api";
import { useEffect } from "react";

const useGetLinea = (lineaId) => {
  const { data, refetch } = useQuery({
    autoFetch: false,
    queryFn: getLinea,
    onError: (err) => showErrorModal({ err }),
  });

  const fetch = () => {
    refetch(lineaId);
  }

  useEffect(() => {
    if (!lineaId) return;
    fetch();
  }, [lineaId]);

  return {
    linea: data?.data?.linea,
    fetchLinea: fetch,
  }
}

export default useGetLinea;