import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

if (process.env.NODE_ENV === "production") {
  console.log = function () {};
  console.warn = function () {};
  console.error = function () {};
}

ReactDOM.createRoot(document.getElementById('root')).render(<App />);
