import { message, Modal, Transfer, Tree } from "antd";
import useMutation from "hooks/useMutation";
import useStyles from "hooks/useStyles";
import { useState } from "react";
import { showErrorModal } from "utils";
import { saveTramosCarga } from "../api";
import useTramosCarga from "./useTramosCarga";

var __rest =
  (this && this.__rest) ||
  function (s, e) {
    var t = {};
    for (var p in s)
      if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === 'function')
      for (var i = 0, symbol = Object.getOwnPropertySymbols(s); i < symbol.length; i++) {
        if (e.indexOf(symbol[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, symbol[i]))
          t[symbol[i]] = s[symbol[i]];
      }
    return t;
  };

const TramosCarga = ({ selectedLineaTramo, onClose }) => {
  const {
    dataSource,
    targetKeys,
    loading,
    onChange,
  } = useTramosCarga(selectedLineaTramo?.id);
  const { algorithm } = useStyles();

  const update = useMutation({
    mutationFn: saveTramosCarga,
    onSuccess: (res) => {
      message.success(res.message);
      onClose();
    },
    onError: err => showErrorModal({ err }),
  });

  const handleSave = () => {
    const body = {
      lineas_tramos_id: selectedLineaTramo?.id,
      lineas_tramos_carga: targetKeys?.map(key => ({
        lineas_tramos_id: selectedLineaTramo?.id,
        destino: key
      }))
    };
    update.mutate(body);
  };

  const generateTree = (treeNodes = [], checkedKeys = []) =>
    treeNodes.map(({ children, ...props }) => {
      const hasChildren = Array.isArray(children) && children.length > 0;
      return {
        ...props,
        disabled: checkedKeys?.includes(props.key),
        ...(hasChildren && { children: generateTree(children, checkedKeys) }), // Solo agrega si hay hijos
      };
    });

  const getAllChildKeys = (children) => {
    if (!children) return [];
    return children.reduce((keys, child) => {
      keys.push(child.key);
      if (child.children) {
        keys.push(...getAllChildKeys(child.children));
      }
      return keys;
    }, []);
  };

  const TreeTransfer = _a => {
    var { dataSource, targetKeys = [] } = _a,
      restProps = __rest(_a, ['dataSource', 'targetKeys']);

    const [filteredData, setFilteredData] = useState(dataSource);

    const transferDataSource = [];
    function flatten(list = []) {
      list.forEach(item => {
        transferDataSource.push(item);
        flatten(item.children);
      });
    }
    flatten(dataSource);

    const filterTree = (nodes, searchValue) => {
      return nodes
        .map(node => {
          if (
            node.title.toLowerCase().includes(searchValue.toLowerCase()) ||
            (node.children && filterTree(node.children, searchValue).length > 0)
          ) {
            return {
              ...node,
              children: node.children ? filterTree(node.children, searchValue) : undefined,
            };
          }
          return null;
        })
        .filter(node => node !== null);
    };

    const isChecked = (selectedKeys, eventKey) => selectedKeys?.includes(eventKey);
    const areChecked = (selectedKeys, keys) => keys.every(key => selectedKeys?.includes(key));
    
    return (
      <Transfer
        {...restProps}
        targetKeys={targetKeys}
        dataSource={transferDataSource}
        className="tree-transfer"
        render={item => item.title}
        showSelectAll
        titles={["Destinos", "Destinos asignados"]}
        selectAllLabels={["", ""]}
        selectionsIcon={false}
        showSearch
        onSearch={(_, searchValue) => {
          if (!searchValue) {
            setFilteredData(dataSource);
          } else {
            setFilteredData(filterTree(dataSource, searchValue));
          }
        }}
        listStyle={{
          width: 400,
          height: 500,
          overflowY: 'auto'
        }}
      >
        {({ direction, onItemSelect, onItemSelectAll, selectedKeys }) => {
          if (direction === 'left') {
            const checkedKeys = [...selectedKeys, ...targetKeys];
            return (
              <Tree
                blockNode
                checkable
                checkStrictly
                defaultExpandAll={false}
                checkedKeys={checkedKeys}
                style={{ backgroundColor: algorithm === "dark" ? "#1F1F1F" : "#FFFFFF" }}
                treeData={generateTree(filteredData, targetKeys)}
                onCheck={(_, { node: { key, children } }) => {
                  const childrenKeys = getAllChildKeys(children).concat(key);
                  onItemSelect(key, !isChecked(checkedKeys, key));
                  onItemSelectAll(childrenKeys, !areChecked(checkedKeys, childrenKeys))
                }}
                onSelect={(_, { node: { key, children } }) => {
                  const childrenKeys = getAllChildKeys(children).concat(key);
                  onItemSelect(key, !isChecked(checkedKeys, key));
                  onItemSelectAll(childrenKeys, !areChecked(checkedKeys, childrenKeys))
                }}
              />
            );
          }
        }}
      </Transfer>
    );
  };

  return (
    <Modal
      title={`Destinos de Carga para ${selectedLineaTramo?.tramo}`}
      open={true}
      forceRender
      onOk={handleSave}
      onCancel={onClose}
      destroyOnClose
      okText="Guardar"
      width={1000}
      style={{ top: 50 }}
      confirmLoading={loading || update.loading}
    >
      <TreeTransfer
        dataSource={dataSource}
        targetKeys={targetKeys}
        onChange={onChange}
      />
    </Modal>
  );
};

export default TramosCarga;