import { useState } from "react"
import useGetFiliales from "hooks/useGetFiliales"
import { filterOption } from "utils";
import { Button, Drawer, Select, Tooltip } from "antd"
import { HomeOutlined } from "@ant-design/icons"
import useCambiarFilial from "./useCambiarFilial";
import useStyles from "hooks/useStyles";

const CambiarFilial = ({ usuario }) => {
  const { colorWhite, fontSizeLG, headerItemHeight } = useStyles();
  const { filialesOptions } = useGetFiliales({ origen: "auth" });
  const [open, setOpen] = useState(false);
  const { handleCambiarFilial, loading } = useCambiarFilial();

  const onChange = async (filialId) => {
    handleCambiarFilial(usuario?.id, filialId)
  };

  return (
    <>
      {(usuario.super || usuario.cambia_filial) &&
        <>
          <Tooltip title="Cambiar filial" mouseLeaveDelay={0}>
            <Button
              type="text"
              style={{
                color: colorWhite,
                fontSize: fontSizeLG,
                height: headerItemHeight,
              }}
              icon={<HomeOutlined />}
              onClick={() => setOpen(true)}
            />
          </Tooltip>
          <Drawer
            title="Cambiar filial"
            onClose={() => setOpen(false)}
            open={open}
            placement="top"
            height={140}
          >
            <Select
              value={usuario?.filiales_id}
              options={filialesOptions}
              placeholder="Seleccionar"
              filterOption={filterOption}
              onChange={onChange}
              showSearch
              style={{ width: '100%' }}
              loading={loading}
            />
          </Drawer>
        </>
      }
    </>
  );
};


export default CambiarFilial;