import { PictureOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import useStyles from "hooks/useStyles";

const ImagenButton = ({
  onClick,
  color,
  ...arg
}) => {
  const { colorPrimary } = useStyles();

  return (
    <Tooltip title="Imagenes" mouseLeaveDelay={0}>
      <Button
        type="text"
        onClick={onClick}
        icon={<PictureOutlined style={{ color: color ? color : colorPrimary }} />}
        {...arg}
      />
    </Tooltip>
  )
};

export default ImagenButton;