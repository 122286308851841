import { Modal, List, Typography, Input, Button, FloatButton, Tag } from "antd";
import { LogoutOutlined, SearchOutlined } from "@ant-design/icons";
import { useState } from "react";
import useCambiarFilial from "components/CambiarFilial/useCambiarFilial";
import useLogout from "hooks/useLogout";
import { rolColors } from "constants/demoData";

const ElegirFilialUsuario = ({ filiales, usuarioId, onClose }) => {
  const { handleCambiarFilial, loading } = useCambiarFilial('CAMBIAR_FILIAL');
  const { logout } = useLogout();
  const [searchValue, setSearchValue] = useState("");
  const isFilialSelected = localStorage.getItem("isFilialSelected");
  const many = filiales.length > 10;
  const filteredFiliales = filiales.filter((filial) =>
    filial.nombre.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <Modal
      open
      title="Seleccione una Filial"
      okButtonProps={{
        style: {
          display: "none",
        },
      }}
      style={filiales.length > 4 && { top: "15px" }}
      confirmLoading={loading}
      closable={false}
      footer={
        <>
          {isFilialSelected && (
            <Button onClick={onClose}>
              Cancelar
            </Button>
          )}
          <Button type="primary" danger icon={<LogoutOutlined />} onClick={logout}>
            Cerrar sesión
          </Button>
        </>
      }
      width={500}
    >
      {many && (
        <Input
          placeholder="Buscar filial..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          prefix={<SearchOutlined style={{ color: "#999" }} />}
          style={{
            marginBottom: "16px",
          }}
        />
      )}

      <div
        id="scrollable-container"
        style={{
          maxHeight: "420px",
          overflowY: "auto",
          paddingRight: "8px",
        }}
      >
        <List
          dataSource={filteredFiliales}
          renderItem={(filial) => (
            <List.Item
              key={filial.id}
              onClick={() => handleCambiarFilial(usuarioId, filial.id)}
              style={{
                cursor: "pointer",
                padding: "12px",
                borderBottom: "1px solid #f0f0f0",
              }}
            >
              <div>
                <Typography.Text>
                  {filial.codigo} - {filial.nombre}
                </Typography.Text>
              </div>
              <div>
                {filial?.roles?.map((rol, i) => (
                  <Tag
                    key={i}
                    style={{ fontSize: 10 }}
                    color={rolColors[rol.id]}
                  >
                    {rol.rol}
                  </Tag>
                ))}
              </div>
            </List.Item>
          )}
        />

        {filteredFiliales.length === 0 && (
          <Typography.Text
            type="secondary"
            style={{
              display: "block",
              textAlign: "center",
              marginTop: "16px",
            }}
          >
            No se encontraron filiales.
          </Typography.Text>
        )}
      </div>
      <FloatButton.BackTop
        style={{ insetInlineEnd: 950 }}
        target={() => document.getElementById("scrollable-container")}
      />
    </Modal>
  );
};

export default ElegirFilialUsuario;
