import { Button, Tooltip } from "antd"
import React from 'react';
import { DownloadOutlined } from "@ant-design/icons"
import useStyles from "hooks/useStyles";

const DownloadButton = ({ onClick, loading }) => {
  const { colorPrimary } = useStyles();

  return (
    <Tooltip title="Descargar" mouseLeaveDelay={0}>
      <Button
        icon={<DownloadOutlined />}
        type="text"
        size="large"
        style={{ color: colorPrimary }}
        onClick={onClick}
        loading={loading}
      />
    </Tooltip>
  );
};

export default DownloadButton;