import { theme } from "antd"
import { useSelector } from 'react-redux';

const Footer = () => {
  const { token: { colorWhite } } = theme.useToken()
  const tema = useSelector(state => state.general.tema);
  const año = new Date().getFullYear()
  const text = `© ${año} CREDIFIN LOGÍSTICA`;

  return (
    <div
      style={{
        textAlign: 'center',
        position: "sticky",
        height: 48
      }}
    >
      <p style={{ color: tema === 1 && colorWhite }}>
        {text}
      </p>
    </div>
  )
};

export default Footer;