import api from "api"
import { Modal } from "antd"
import useMutation from "hooks/useMutation"
import { ExclamationCircleFilled } from '@ant-design/icons';
import { CONFIRMATION_MESSAGES } from "constants";

const useBajaAlta = (callback) => {

  const bajaAltaMutation = useMutation({
    mutationFn: api.general.bajaAlta,
    onSuccess: response => {
      if (response.status === 'success') {
        callback && callback()
      }
    },
    onError: err => {
      Modal.error({
        title: err.message || JSON.stringify(err),
      });
    },
  });

  const bajaAlta = (accion, tabla, id, principal) => {
    if (principal) {
      Modal.error({
        title: 'Error',
        content: 'No se puede eliminar este registro porque está marcado como principal. Debe asignar otro registro como principal antes de proceder con la eliminación.'
      });
      return;
    }

    Modal.confirm({
      title: accion === 'baja' ? CONFIRMATION_MESSAGES.DELETE_CONFIRMATION : '',
      icon: <ExclamationCircleFilled />,
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      maskClosable: false,
      onOk() {
        bajaAltaMutation.mutate({ accion, tabla, id });
      },
    });
  };

  return {
    bajaAlta,
  }
};

export default useBajaAlta;