import { Badge, Button, Layout, Tooltip, Space } from "antd"
import UserMenu from "./UserMenu"
import { Link } from "react-router-dom"
import {
  EnvironmentOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from "@ant-design/icons"
import useStyles from "hooks/useStyles"
import { useSelector } from "react-redux"
import CambiarFilial from "components/CambiarFilial"

const headerItems = [
  {
    title: "Clientes",
    href: "/clientes",
    icon: <UserOutlined />
  },
];

const Header = ({ collapsed, setCollapsed }) => {
  const usuario = useSelector(state => state.general.usuario);

  const {
    colorPrimary,
    colorWhite,
    fontSizeLG,
    headerItemHeight
  } = useStyles();

  const headerStyles = {
    padding: 0,
    display: "flex",
    background: colorPrimary,
    justifyContent: "space-between"
  };

  const buttonStyles = {
    color: colorWhite,
    fontSize: fontSizeLG,
    width: headerItemHeight,
    height: headerItemHeight,
  };

  return (
    <Layout.Header style={headerStyles}>
      <div>
        <Button
          type="text"
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => setCollapsed(!collapsed)}
          style={buttonStyles}
        />
        {headerItems.map((item, index) => {
          return (
            <Link to={item.href} key={index}>
              <Tooltip title={item.title}>
                <Button
                  type='text'
                  style={buttonStyles}
                >
                  {item.title === "Pedidos de retiro"
                    ?
                    <Badge count={1} size="small" style={{ border: "none" }}>
                      {item.icon}
                    </Badge>
                    :
                    item.icon
                  }
                </Button>
              </Tooltip>
            </Link>
          )
        })}
      </div>
      <div>
        <Space direction="horizontal" size="none" align="center">
          <span style={{ color: colorWhite, padding: '15px' }}>
            <EnvironmentOutlined /> {usuario?.filial?.nombre}
          </span>
          <CambiarFilial usuario={usuario} />
          <UserMenu usuario={usuario} />
        </Space>
      </div>
    </Layout.Header>
  );
};

export default Header;