import { Button, Tooltip } from "antd"
import React from 'react';
import { DeleteOutlined } from "@ant-design/icons"
import useStyles from "hooks/useStyles";

const DeleteButton = ({ onClick, ...rest }) => {
  const { colorError, colorTextDisabled } = useStyles();

  return (
    <Tooltip title={rest.title || (!rest.disabled && "Eliminar")} mouseLeaveDelay={0}>
      <Button
        type="text"
        onClick={onClick}
        icon={<DeleteOutlined style={{ color: rest.disabled ? colorTextDisabled : colorError }} />}
        {...rest}
      />
    </Tooltip>
  );
};

export default DeleteButton;
