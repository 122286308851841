import { config } from "config";
import { options } from "utils";

export const getAll = async (q) => {
  const url = `${config.URL_API_PRIVATE}/lineas?${q}`;
  const response = await fetch(url, options("GET"));
  const data = await response.json();
  return data;
};

export const getLinea = async (id) => {
  const url = `${config.URL_API_PRIVATE}/lineas/${id}`;
  const response = await fetch(url, options("GET"));
  const data = await response.json();
  return data;
};

export const getAllLineas = async (q = '') => {
  const url = `${config.URL_API_PRIVATE}/lineas/all?${q}`;
  const response = await fetch(url, options("GET"));
  const data = await response.json();
  return data;
};

export const createLinea = async (body) => {
  const url = `${config.URL_API_PRIVATE}/lineas`;
  const response = await fetch(url, options("POST", body));
  const data = await response.json();
  return data;
};

export const updateLinea = async (body) => {
  const url = `${config.URL_API_PRIVATE}/lineas`;
  const response = await fetch(url, options("PUT", body));
  const data = await response.json();
  return data;
};

export const marcarSalida = async (body) => {
  const url = `${config.URL_API_PRIVATE}/distribuciones/planificacion/marcarsalida`;
  const response = await fetch(url, options("PUT", body));
  const data = await response.json();
  return data;
};

export const deleteLinea = async (id) => {
  const url = `${config.URL_API_PRIVATE}/lineas/${id}`;
  const response = await fetch(url, options("DELETE"));
  const data = await response.json();
  return data;
};