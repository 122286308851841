import { BranchesOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Form, Input, message, Modal, Select, Table, Tooltip } from "antd";
import { DeleteButton } from "components/buttons";
import ContentWithHeader from "components/Layout/AppLayout/Content/ContentWithHeader";
import { RULE_REQUIRED } from "constants";
import useBajaAlta from "hooks/useBajaAlta";
import useColumnSearchServer from "hooks/useColumnSearchServer";
import useGetTramos from "hooks/useGetTramos";
import useLineasTramos from "hooks/useLineasTramos";
import useMutation from "hooks/useMutation";
import useRedirectIfInvalid from "hooks/useRedirectIfInvalidId;";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { filterOption, normalizarTexto, showErrorModal } from "utils";
import { updateLinea } from "../api";
import useGetLinea from "../useGetLinea";
import { createLineaTramo, deleteAll } from "./api";
import TramosCarga from "./TramosCarga/index";

const Tramos = () => {
  const location = useLocation();
  useRedirectIfInvalid(location.state);

  const [form] = Form.useForm()
  const { resetFields } = form;
  const { linea, fetchLinea } = useGetLinea(location.state?.linea?.id);
  const { fetch, onTableChange, onSearch, data: lineasTramos, loading: loadingTramos } = useLineasTramos(linea?.id);
  const { tramos, fetchTramos: fetchTramosOption, loading: loadingTramosOption } = useGetTramos();
  const { getColumnSearchProps } = useColumnSearchServer(onSearch);
  const [selectedLineaTramo, setSelectedLineaTramo] = useState(null);
  const [openTramosCarga, setOpenTramosCarga] = useState(false);
  const [editarLinea, setEditarLinea] = useState(false);
  const [lineaNombre, setLineaNombre] = useState("");
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const ultimoTramo = lineasTramos && lineasTramos[lineasTramos.length - 1];

  useEffect(() => {
    if (ultimoTramo?.destino) {
      fetchTramosOption(ultimoTramo?.destino);
    } else {
      fetchTramosOption();
    }
  }, [ultimoTramo?.destino]);

  useEffect(() => {
    if (!linea?.linea) return;
    setLineaNombre(linea?.linea);
  }, [linea?.linea]);

  const handleFetch = () => {
    fetch(linea?.id);
  };

  const { bajaAlta } = useBajaAlta(() => handleFetch());

  const create = useMutation({
    mutationFn: createLineaTramo,
    onSuccess: () => {
      handleFetch();
      resetFields();
    },
    onError: err => showErrorModal({ err }),
  });

  const onFinish = (values) => {
    const body = {
      ...values,
      lineas_id: linea?.id,
      orden: !ultimoTramo ? 1 : ultimoTramo?.orden + 1,
    };
    create.mutate(body);
  };

  const update = useMutation({
    mutationFn: updateLinea,
    onSuccess: (res) => {
      message.success(res.message);
      fetchLinea();
    },
    onError: err => {
      showErrorModal({ err });
    },
  });

  const handleEditar = (newNombre) => {
    if (!editarLinea) {
      setEditarLinea(true);
      return;
    }

    if (newNombre?.trim() === linea?.linea) {
      setEditarLinea(false);
      return;
    }

    if (newNombre?.trim().length < 4) {
      return message.error("Nombre de línea demasiado corto.");
    }

    const payload = {
      id: linea?.id,
      linea: newNombre.trim(),
    }

    Modal.confirm({
      title: "Tienes cambios sin guardar",
      content: "¿Desea guardar los cambios?",
      okText: "SI",
      cancelText: "NO",
      onOk: () => {
        update.mutate(payload);
        setEditarLinea(false);
      },
      onCancel: () => {
        setLineaNombre(linea?.linea);
        setEditarLinea(false);
      }
    });
  }

  const eliminarTodo = useMutation({
    mutationFn: deleteAll,
    onSuccess: (res) => {
      handleFetch();
      fetchTramosOption();
      message.success(res.message);
    },
    onError: err => message.error(err.message),
  });

  const showDeleteAllConfirm = async () => {
    Modal.confirm({
      title: '¿Está seguro de que desea eliminar todos los tramos?',
      okText: 'SI',
      okType: 'danger',
      cancelText: 'NO',
      onOk() {
        eliminarTodo.mutate(linea?.id);
      },
    });
  };

  const breadcrumbItems = [
    {
      title: <Link to="/">Inicio</Link>,
    }, {
      title: <Link to="/lineas">Lineas</Link>,
    }, {
      title: linea?.id,
    },
  ];

  const columns = [
    {
      title: "Orden",
      dataIndex: "orden",
      sorter: true,
      width: 20,
      align: "center",
      ...getColumnSearchProps("orden", "input"),
    },
    {
      title: "Tramo",
      dataIndex: "tramo",
      sorter: true,
      width: 500,
      ...getColumnSearchProps("tramo", "input"),
    },
    {
      title: (lineasTramos?.length > 0 &&
        <DeleteButton
          title="Eliminar todos los tramos"
          size="small"
          onClick={() => showDeleteAllConfirm()}
        />
      ),
      align: "center",
      width: 100,
      render: (record, _, index) => {
        const isLast = index === lineasTramos.length - 1;
        return (
          <>
            <Tooltip title="Destino de Carga" mouseLeaveDelay={0}>
              <Button
                size="small"
                type="text"
                icon={<BranchesOutlined />}
                onClick={() => {
                  setOpenTramosCarga(true);
                  setSelectedLineaTramo(record);
                }}
              />
            </Tooltip>
            <DeleteButton
              title="Eliminar tramo"
              size="small"
              disabled={!isLast}
              onClick={() => bajaAlta("baja", "lineas_tramos", record.id)}
            />
          </>
        );
      },
    },
  ];

  return (
    <ContentWithHeader
      title={
        <Input
          size="small"
          style={{ fontSize: "20px", width: 600 }}
          maxLength={50}
          bordered={editarLinea}
          readOnly={!editarLinea}
          value={lineaNombre}
          onChange={(e) => {
            setLineaNombre(e.target.value);
            setHasUnsavedChanges(true);
          }}
          onInput={(e) => normalizarTexto(e, ["-", "ñ"])}
          onBlur={() => {
            if (lineaNombre?.trim() === linea?.linea) {
              setHasUnsavedChanges(false);
              setEditarLinea(false);
              return;
            }
            if (!hasUnsavedChanges) return;
            handleEditar(lineaNombre);
          }}
          prefix={
            <Button
              type="text"
              icon={!editarLinea ? <EditOutlined /> : <SaveOutlined />}
              onMouseDown={() => setHasUnsavedChanges(false)}
              onClick={() => handleEditar(lineaNombre)}
            />
          }
          onDoubleClick={() => handleEditar(lineaNombre)}
        />
      }
      breadcrumb={<Breadcrumb items={breadcrumbItems} />}
    >
      <Form
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        style={{ display: 'flex', gap: 12 }}
      >
        <Form.Item
          label="Tramo"
          name="tramos_id"
          rules={[RULE_REQUIRED]}
        >
          <Select
            options={tramos
              ?.filter(tramo =>
                !lineasTramos?.some(d => d.origen === tramo.origen || d.destino === tramo.destino)
              )
              ?.map(tramo => ({
                value: tramo.id,
                label: `${tramo.filial_origen} - ${tramo.filial_destino}`,
              }))}
            showSearch
            filterOption={filterOption}
            style={{ width: 400, marginLeft: 12 }}
            placeholder="Seleccionar"
            loading={loadingTramosOption}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={create.loading}>
            Agregar
          </Button>
        </Form.Item>
      </Form>
      <Table
        size="small"
        pagination={false}
        onChange={onTableChange}
        dataSource={lineasTramos}
        rowKey="id"
        columns={columns}
        loading={loadingTramos}
        scroll={lineasTramos?.length > 8 && { y: 300 }}
        onRow={record => ({
          onDoubleClick: () => {
            setOpenTramosCarga(true);
            setSelectedLineaTramo(record);
          }
        })}
      />
      {openTramosCarga &&
        <TramosCarga
          selectedTramoId={selectedLineaTramo?.tramos_id}
          selectedLineaTramo={selectedLineaTramo}
          onClose={() => {
            setOpenTramosCarga(false);
            fetch(linea?.id);
          }}
        />
      }
    </ContentWithHeader>
  );
}

export default Tramos;