import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useRedirectIfInvalid = (state) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!state) {
      navigate("/not-found");
    }
  }, [state, navigate]);
};

export default useRedirectIfInvalid;
