import { Button, Tooltip, Badge } from "antd"
import React from 'react';
import { PaperClipOutlined } from "@ant-design/icons"
import useStyles from "hooks/useStyles";

const AdjuntosButton = ({ onClick, count }) => {
  const { colorPrimary } = useStyles();

  return (
    <Tooltip title="Ver" mouseLeaveDelay={0}>
      <Badge count={count} style={{ position: 'absolute', right: 7 }}>
        <Button
          icon={<PaperClipOutlined />}
          type="text"
          size="large"
          style={{ color: colorPrimary }}
          onClick={onClick}
        />
      </Badge>
    </Tooltip>
  );
};

export default AdjuntosButton;