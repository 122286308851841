import useQuery from "hooks/useQuery";
import { getAll } from "../routes/lineas/Tramos/api"
import { showErrorModal } from "utils"
import { useEffect, useState } from 'react';
import queryString from 'query-string';

const useLineasTramos = (lineaId) => {
  const [sorter, setSorter] = useState({ field: null, order: null });
  const [searchValue, setSearchValue] = useState("")
  const [searchedColumn, setSearchedColumn] = useState('');

  const { refetch, data, loading } = useQuery({
    autoFetch: false,
    queryFn: getAll,
    onError: (err) => showErrorModal({ err }),
  });

  useEffect(() => {
    if (!lineaId) return;
    fetch(lineaId);
  }, [lineaId, searchValue, sorter]);

  const fetch = (lineaId) => {
    const query = queryString.stringify({
      searchValue,
      searchedColumn,
      sortField: sorter.field,
      sortOrder: sorter.order === "ascend" ? "ASC" : "DESC",
      lineas_id: parseInt(lineaId),
    });
    refetch(query);
  };


  const onTableChange = (pagination, _, sorter) => {
    setSorter(sorter);
  };

  const onSearch = (searchValue, searchedColumn) => {
    setSearchValue(searchValue);
    setSearchedColumn(searchedColumn);
  };

  return {
    fetch,
    onTableChange,
    onSearch,
    data: data?.data?.lineas_tramos,
    loading,
  }
};

export default useLineasTramos;