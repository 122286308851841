import { Button, Input, Select } from "antd"
import React, { useState } from 'react';
import { FilterOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { filterOption } from "utils";

const useColumnSearchServer = (onSearch) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    const searchTerm = selectedKeys[0]?.toString().trim();

    confirm();
    setSearchTerm(searchTerm);
    setSearchedColumn(dataIndex);

    onSearch(searchTerm, dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchTerm('');
    setSearchedColumn('');
    onSearch('', '');
  };

  const getColumnSearchProps = (dataIndex, inputType, options_, showSearch) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        {inputType === 'input' && (
          <Input
            placeholder="Buscar"
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 250, marginBottom: 8, display: 'block' }}
          />
        )}
        {inputType === 'select' && (
          <Select
            placeholder="Seleccionar"
            value={selectedKeys[0]}
            onChange={(value) => setSelectedKeys(value ? [value] : [])}
            style={{ width: 250, marginBottom: 8, display: 'block' }}
            options={options_}
            showSearch={showSearch}
            filterOption={filterOption}
          />
        )}
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Resetear
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      inputType === 'input' ?
        <SearchOutlined style={{ color: dataIndex === searchedColumn ? "#FF0000" : '#1890ff' }} />
        :
        <FilterOutlined style={{ color: dataIndex === searchedColumn ? "#FF0000" : '#1890ff' }} />
    ),
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchTerm]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  return {
    getColumnSearchProps,
  }
};

export default useColumnSearchServer;