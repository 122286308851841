import { exclusiveURL } from "constants/demoData";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generalActions } from "redux/generalSlice";
import { getBasePath } from "utils";

const usePermisos = (elemento, data) => {
  const dispatch = useDispatch();
  const usuario = useSelector(state => state.general.usuario);
  const basePath = elemento === "view" && getBasePath(data);
  
  useEffect(() => {
    dispatch(generalActions.setActionsOnView(acceso));
  }, [usuario, basePath]);

  const acceso = {
    alta: false,
    baja: false,
    modificacion: false,
    denegado: true,
    consulta: false,
    total: false,
  }

  if (!usuario) return acceso;
  if (
    usuario.super ||
    usuario?.roles?.some((rol) => rol.id === 1) ||
    // usuario?.filiales?.some((filial) => filial.principal) ||
    exclusiveURL.includes(basePath)
  ) {
    Object.assign(acceso, {
      alta: true,
      baja: true,
      modificacion: true,
      denegado: false,
      consulta: true,
      total: true,
    });

    return acceso;
  };

  const permisos = usuario?.accesos?.flatMap(acceso => acceso)

  const accesoEncontrado = permisos?.find(acceso => {
    let accesoObjeto = null;
    
    switch (elemento) {
      case "view":
        if (acceso?.acceso?.acceso === basePath && acceso?.consulta) {
          accesoObjeto = acceso;
        }
        break;
      case "tab":
        if (acceso?.accesos_id === data && acceso?.consulta) {
          accesoObjeto = acceso;
        }
        break;
      case "button":
        if (acceso?.accesos_id === data && acceso?.total) {
          accesoObjeto = acceso;
        }
        break;
      default:
        break;
    }
    return accesoObjeto !== null ? accesoObjeto : false;
  });

  if (accesoEncontrado) {
    Object.assign(acceso, accesoEncontrado);
  }

  return acceso;
}

export default usePermisos;