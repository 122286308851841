import { Button, Tooltip } from "antd"
import React from 'react';
import { PlusOutlined } from "@ant-design/icons"
import useStyles from "hooks/useStyles";

const AgregarButton = ({ onClick, size }) => {
  const { colorPrimary } = useStyles();

  return (
    <Tooltip title="Agregar" mouseLeaveDelay={0}>
      <Button
        icon={<PlusOutlined />}
        type="text"
        size={size || "large"}
        style={{ color: colorPrimary }}
        onClick={onClick}
      />
    </Tooltip>
  );
};

export default AgregarButton;