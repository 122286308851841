import useStyles from './useStyles';

const useWrapperStyles = () => {
  const { marginSM, marginLG } = useStyles();

  return {
    wrapperStyles: {
      margin: `${marginSM}px ${marginLG}px`
    },
  };
};

export default useWrapperStyles;
