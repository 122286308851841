import { Layout } from 'antd';
import Loading from 'components/Loading';
import { Suspense, useEffect, useState } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import SimpleBar from 'simplebar-react';
import CambiarClave from 'components/CambiarClave';
import Footer from './Footer';
import useAuth from 'hooks/useAuth';
import React from 'react';
import ElegirFilialUsuario from 'components/ElegirFilialUsuario';

const AppLayout = ({ children }) => {
  const { Content } = Layout;

  const [collapsed, setCollapsed] = useState(false);
  const { usuario, loading } = useAuth();
  
  const [openFiliales, setOpenFiliales] = useState(false);
  const isFilialSelected = localStorage.getItem("isFilialSelected");

  useEffect(() => {
    if (!usuario) return;

    setOpenFiliales(usuario?.filiales?.length > 1 && isFilialSelected !== 'true');
  }, [usuario]);

  if (!usuario || loading) {
    return <Loading fullscreen />
  }

  return (
    <Layout>
      {usuario.cambiarcontraseña &&
        <CambiarClave />
      }
      {openFiliales &&
        <ElegirFilialUsuario
          filiales={usuario?.filiales}
          usuarioId={usuario?.id}
        />
      }
      <Sidebar collapsed={collapsed} />
      <Layout >
        <Header collapsed={collapsed} setCollapsed={setCollapsed} />
        <Content>
          <Suspense fallback={<Loading />}>
            <SimpleBar >
              {children}
            </SimpleBar>
          </Suspense>
        </Content>
        <Footer />
      </Layout>
    </Layout>
  );
};

export default AppLayout