import { Button, Row } from "antd"
import { PlusOutlined } from "@ant-design/icons"

const NuevoButton = ({ onClick, size }) => {
  return (
    <Row justify="end" style={{ marginBottom: '10px' }}>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={onClick}
        size={size}
      >
        Nuevo
      </Button>
    </Row>
  );
};

export default NuevoButton;