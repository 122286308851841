import queryString from "query-string";
import { getAllTramos } from "routes/tramos/api";
import { showErrorModal } from "utils";
import useQuery from "./useQuery";

const useGetTramos = () => {
  const { refetch, data, loading } = useQuery({
    autoFetch: false,
    queryFn: getAllTramos,
    onError: err => {
      showErrorModal({ err });
    }
  });

  const fetch = (oFilialesId) => {    
    const query = queryString.stringify({
      oFilialesId
    });

    refetch(query);
  };

  const tramos = data?.data?.tramos;
  const tramosOptions = tramos?.map(data => ({
    value: data.id,
    label: `${data.filial_origen} - ${data.filial_destino}`,
  }));

  return {
    tramos,
    tramosOptions,
    fetchTramos: (e) => fetch(e),
    loading,
  }
};

export default useGetTramos;