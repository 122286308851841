import { options } from "utils";
import { config } from "config";

export const getAll = async (q) => {
  const url = `${config.URL_API_PRIVATE}/lineas/tramos?` + q
  const response = await fetch(url, options("GET"));
  const data = await response.json();
  return data;
};

export const getAllLineaTramos = async (id) => {
  const url = `${config.URL_API_PRIVATE}/lineas/${id}/tramos`
  const response = await fetch(url, options("GET"));
  const data = await response.json();
  return data;
};

export const createLineaTramo = async (body) => {
  const url = `${config.URL_API_PRIVATE}/lineas/tramos`
  const response = await fetch(url, options("POST", body));
  const data = await response.json();
  return data;
};

export const updateLineaTramo = async (body) => {
  const url = `${config.URL_API_PRIVATE}/lineas/tramos`
  const response = await fetch(url, options("PUT", body));
  const data = await response.json();
  return data;
};

export const deleteAll = async (id) => {
  const url = `${config.URL_API_PRIVATE}/lineas/${id}/tramos`
  const response = await fetch(url, options("DELETE"));
  const data = await response.json();
  return data;
};

export const saveTramosCarga = async (body) => {
  const url = `${config.URL_API_PRIVATE}/lineas/tramos/carga`
  const response = await fetch(url, options("PUT", body));
  const data = await response.json();
  return data;
};

export const getFiliales = async (id) => {
  const response = await fetch(config.URL_API_PRIVATE + `/lineas/tramos/${id}/filiales`, options("GET"))
  const data = await response.json();
  return data;
};