import useQuery from "hooks/useQuery";
import { useEffect, useState } from "react";
import { showErrorModal } from "utils";
import { getFiliales } from "../api";

const buildTree = (data) => {
  const filiales = data.map(filial => {
    filial.nodo = filial.id === filial?.nodos_id;
    return { key: filial.id, title: filial.nombre, nodos_id: filial.nodos_id };
  });

  const map = new Map();

  filiales.forEach(filial => {
    if (!map.has(filial.nodos_id)) {
      map.set(filial.nodos_id, []);
    }
    map.get(filial.nodos_id).push(filial);
  });

  const tree = [];

  map.forEach((filialesHijas, nodos_id) => {
    if (nodos_id === null || nodos_id === undefined || nodos_id === 0) {
      filialesHijas.forEach(filial => {
        tree.push({ ...filial });
      });
    } else {
      const nodo = filialesHijas.find(filial => filial.key === nodos_id);
      if (nodo) {
        const children = filialesHijas.filter(filial => filial.nodos_id === nodo.key && filial.key !== filial.nodos_id);

        if (children.length > 0) {
          nodo.children = children;
          
          children.forEach(child => {
            const grandchildren = filiales.filter(filial => filial.nodos_id === child.key && child.key !== child.nodos_id);
            child.children = grandchildren;
          })
        }

        if (!tree.some(f => f.key === nodo.key)) {
          tree.push(nodo);
        }
      }
    }
  });
  return tree;
};

const useTramosCarga = (selectedLineaTramoId) => {
  const [buscar, setBuscar] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);

  const query = useQuery({
    args: [null],
    queryFn: getFiliales,
    onSuccess: res => {
      const tree = buildTree(res?.data?.filiales);
      setDataSource(tree);
      setBuscar(true);
    },
    onError: err => showErrorModal({ err }),
  });

  const initialQuery = useQuery({
    autoFetch: false,
    queryFn: getFiliales,
    onSuccess: res => {
      const initialKeys = res?.data?.filiales?.map(item => item.id);
      setTargetKeys(initialKeys);
      setBuscar(false);
    },
    onError: err => showErrorModal({ err }),
  });

  const onChange = (nextTargetKeys) => {
    setTargetKeys(nextTargetKeys);
  };

  const fetch = () => {
    initialQuery.refetch(selectedLineaTramoId);
  }

  useEffect(() => {
    if (!buscar) return;
    fetch();
  }, [buscar]);

  return {
    dataSource,
    setDataSource,
    targetKeys,
    setTargetKeys,
    onChange,
    loading: (query.loading || initialQuery.loading)
  }
};

export default useTramosCarga;